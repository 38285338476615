import "./styles/partnerzy.css";
import logoDolnySlask from "../pictures/logos/marszalek.png";
import logoPWR from "../pictures/logos/pwr.webp";
import logoRadioLuz from "../pictures/logos/radio-luz.webp";
import dlastudenta from "../pictures/logos/dlastudenta.png";
import pozytekPubliczny from "../pictures/logos/pozytekpubliczny.png";
import TSG from "../pictures/logos/tsg.png";
import Familijna from "../pictures/logos/familijna.png";

export default function PartnerzyPage() {
  return (
    <section id="partnerzy" className="appear">
      <div className="partnerzy--boxes">
        <h3>Poprzedni Partner</h3>
        <div className="partnerzy--box">
          <a href="https://tensquaregames.com/">
            <img className="logo-big" src={TSG} alt="Logo Unit8" />
          </a>
        </div>
        <h3>Poprzedni Partner strategiczny</h3>
        <div className="partnerzy--box">
          <a href="https://familijna.pl/">
            <img src={Familijna} alt="cos tam" />
          </a>
        </div>
        <h3>Poprzednie Patronaty honorowe</h3>
        <div className="partnerzy--box">
          <img src={logoPWR} alt="" />
          <img src={logoDolnySlask} alt="" />
          <img id="long--logo" src={pozytekPubliczny} alt="" />
        </div>
        <h3>Poprzednie Partronaty medialne</h3>
        <div className="partnerzy--box">
          <img src={dlastudenta} alt="cos tam" />
          <img src={logoRadioLuz} alt="cos tam" />
        </div>
      </div>
    </section>
  );
}
