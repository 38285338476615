const dataCT = [
  {
    id: 1,
    name: "Mateusz Salach",
    title: "Koordynator Główny",
    shortTitle: "MO",
    description: "",
    img: "./core-team/salach.jpg",
    alt: "Mateusz Salach, Organizator Główny",
    color: "red",
  },
  {
    id: 2,
    name: "Kamil Taras",
    title: "Koordynator ds. Merytoryki",
    shortTitle: "TR",
    description: "",
    img: "./core-team/kamil.jpg",
    alt: "Kamil Taras, Opiekun BIT Jam",
    color: "blue",
  },
  {
    id: 3,
    name: "Paulina Michalska",
    title: "Koordynatorka ds. Kontaktu z Firmami",
    shortTitle: "FR",
    description: "",
    img: "./core-team/paula.jpg",
    alt: "Paulina Michalska, Koordynator ds. Kontaktu z Firmami",
    color: "blue",
  },
  {
    id: 4,
    name: "Wiktoria Kudła",
    title: "Koordynatorka ds. Kontaktu z Firmami",
    shortTitle: "FR",
    description: "",
    img: "./core-team/wika.jpg",
    alt: "Wiktoria Kudła, Koordynator ds. Kontaktu z Firmami",
    color: "blue",
  },
  {
    id: 5,
    name: "Julia Różycka",
    title: "Koordynatorka ds. Grafiki",
    shortTitle: "Designer",
    description: "",
    img: "./core-team/julka.jpg",
    alt: "Julia Różycka, Koordynator ds. Grafiki",
    color: "red",
  },

  {
    id: 6,
    name: "Paweł Leyk",
    title: "Koordynator ds. Promocji",
    shortTitle: "PR",
    description: "",
    img: "./core-team/pawelek.jpg",
    alt: "Paweł Leyk, PR",
    color: "red",
  },
  {
    id: 7,
    name: "Weronika Jóżak",
    title: "Koordynatorka ds. Logistyki",
    shortTitle: "LG",
    description: "",
    img: "./core-team/werka.jpg",
    alt: "Weronika Jóżak, LG",
    color: "blue",
  },
  {
    id: 8,
    name: "Bartosz Kaczor",
    title: "Koordynator ds. Rozrywki",
    shortTitle: "CR",
    description: "",
    img: "./core-team/bartek.jpg",
    alt: "Bartosz Kaczor, CR",
    color: "red",
  },
  {
    id: 9,
    name: "Robert Dworak",
    title: "Koordynator ds. Rozrywki",
    shortTitle: "CR",
    description: "",
    img: "./core-team/robert.jpg",
    alt: "Robert Dworak, CR",
    color: "blue",
  },
  {
    id: 10,
    name: "Julia Gacal",
    title: "Koordynatorka ds. Zasobów ludzkich",
    shortTitle: "HR",
    description: "",
    img: "./core-team/julia-g.jpg",
    alt: "Julia Gacal, HR" /*rozwiniecie*/,
    color: "blue",
  },
  {
    id: 11,
    name: "Adrian Augustyn",
    title: "Przedstawiciel Zarządu",
    shortTitle: "Angel",
    description: "",
    img: "./core-team/adi.jpg",
    alt: "Adrian Augustyn, Opiekunka Projektu",
    color: "red",
  },
  {
    id: 12,
    name: "Weronika Najda",
    title: "Przedstawicielka Zarządu",
    shortTitle: "Angel",
    description: "",
    img: "./core-team/najda.jpg",
    alt: "Weronika Najda, Opiekun Projektu",
    color: "blue",
  },
];
export default dataCT;
