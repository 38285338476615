import "./styles/footer.css"

export default function Footer () {
    return (
        <section className="footer">
            <p>
                BEST Wrocław © 2022 Jakub Iwaszkiewicz & Adrian Augustyn
            </p>
        </section>
    )
}