import klawa from "../pictures/janusz/turniej.jpg";
import "./styles/turniej.css";
import "./styles/button.css";

export default function tftPage() {
  return (
    <section id="tft">
      <div className="tur-container appear">
        <div className="tur-content-cont">
          <div className="tur-cont-img">
            <img src={klawa} alt="Turniej TFT."></img>
          </div>
          <div className="tur-cont-cont">
            <h3>Turniej TFT</h3>
            <div className="tur-cont-text">
              <p>
                Jeśli jesteś miłośnikiem taktyki i rywalizacji możesz spróbować
                swoich sił w naszym turnieju Teamfight Tactics! Już 25.
                listopada w SKS-ie, dojdzie do starcia 8 gigantów strategii.
                Chcesz być jednym z nich?
              </p>
            </div>
            <div className="tur-cont-btn">
              <a
                className="a-button"
                href="https://drive.google.com/file/d/1soIXxiwebmSc9oifyw0bd89NeCnxdTlv/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                <div className="rose-neon-button">Regulamin</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
